import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import RefreshButton from "./RefreshButton";
import DateAndTime from "../components/DateAndTime";

const DeviceDataUsageHistory = (props) => {
 const { setError, setLoading, loading } = props;
  const { device_uuid } = useParams();
  const [dataUsageHistoryResults, setDataUsageHistoryResults] = useState();
  const [tableData, setTableData] = useState([]);


  const getDataUsageHistory = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/data-usage-history`)
      .then((response) => {
        if (response.status === 200) {
          setDataUsageHistoryResults(response.data.items);
        }
      })
      .catch((error) => {
        setError({ text: error.response.data.detail });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDataUsageHistory();
    // eslint-disable-next-line
  }, []);

    const columns = useRef([
      {
        field: "month",
        headerName: "Month",
        flex: 1,
        renderCell: (params) => {
          const month = moment(params.row.cycle.month, "MM").format("MMMM");
          const year = moment(params.row.cycle.year, "YYYY").format("YYYY");
          return (
            <Typography>
              {month} - {year}
            </Typography>
          );
        },
      },
      {
        field: "used_data",
        headerName: "Used Data",
        flex: 1,
        renderCell: (params) => {
          const usedDataInMB = (params.row.used_data / 1024).toFixed(2);
          return <Typography>{usedDataInMB} MB</Typography>;
        },
      },
      {
        field: "last_updated_at",
        headerName: "Last Updated At",
        flex: 1,
        renderCell: (params) =>
          params.row.last_updated_at && (
            <DateAndTime dateAndTime={params.row.last_updated_at} />
          ),
      },
    ]);

    useEffect(() => {
      const tableDataToUse = [];

      if (dataUsageHistoryResults) {
        for (const dataUsageHistoryRecord of dataUsageHistoryResults) {
          tableDataToUse.push(dataUsageHistoryRecord);
        }
      }
      setTableData(tableDataToUse);
    }, [dataUsageHistoryResults]);
   return (
     <>
       <Grid container direction="column" spacing={3}>
         <Grid item>
           <Box display="flex" justifyContent="flex-end">
             <RefreshButton refreshAction={getDataUsageHistory} />
           </Box>
         </Grid>
         <Grid item>
           <DataGrid
             rows={tableData}
             columns={columns.current}
             disableSelectionOnClick
             autoHeight
             rowCount={tableData.length}
             loading={loading}
             density="compact"
             components={{
               NoRowsOverlay: () => (
                 <Box pt={20}>
                   <Grid
                     container
                     height="100%"
                     alignItems="center"
                     justifyContent="center"
                   >
                     <Grid item>
                       <Typography>No net scan results.</Typography>
                     </Grid>
                   </Grid>
                 </Box>
               ),
             }}
           />
         </Grid>
       </Grid>
     </>
   );
}

DeviceDataUsageHistory.propTypes = {
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DeviceDataUsageHistory;
