import React, { useRef } from "react";
import { Box, Grid, Button, Typography } from "@material-ui/core";
import moment from "moment";
import { DataGrid } from "@material-ui/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";

const ReportIp = ({ reportData }) => {
  const exportDataLink = useRef();

  const export_headers = [
    { label: "Device Id", key: "serial" },
    { label: "Assignment", key: "assignment_type" },
    { label: "Status", key: "status" },
    { label: "Port", key: "port" },
    { label: "Internal Port", key: "internal_port" },
    { label: "Inbound IP", key: "inbound_ip" },
    { label: "Internal IP", key: "internal_ip" },
    { label: "Protocol", key: "protocol" },
    { label: "UUID", key: "uuid" },
    { label: "Rule Id", key: "rule_id" },
    { label: "Created At", key: "created_at" },
    { label: "Updated At", key: "updated_at" },
    { label: "Deleted At", key: "deleted_at" },
  ];

  const columns = [
    {
      field: "serial",
      headerName: "Device Serial No",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "assignment_type",
      headerName: "Assignment",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "port",
      headerName: "Port",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "internal_port",
      headerName: "Internal Port",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "inbound_ip",
      headerName: "Inbound IP",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "internal_ip",
      headerName: "Internal IP",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "protocol",
      headerName: "Protocol",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Action Taken At",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        params.row.created_at
          ? moment.utc(params.row.created_at).local().format("LLL")
          : "-",
    },
    {
      field: "updated_at",
      headerName: "Updated_at",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        params.row.updated_at
          ? moment.utc(params.row.updated_at).local().format("LLL")
          : "-",
    },
    {
      field: "deleted_at",
      headerName: "Deleted At",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        params.row.deleted_at
          ? moment.utc(params.row.deleted_at).local().format("LLL")
          : "-",
    },
  ];

  return (
    <>
      {reportData && reportData.length === 0 && (
        <Box pt={10}>
          <Typography variant="h5" align="center">
            No Report Data.
          </Typography>
        </Box>
      )}
      {reportData && reportData.length !== 0 && (
        <React.Fragment>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={() => {
                  exportDataLink.current.link.click();
                }}
              >
                Export
              </Button>
              <CSVLink
                data={reportData}
                headers={export_headers}
                filename={
                  reportData &&
                  `sparqfi_ip${moment().format("YYYYMMDD_hmmss")}.csv`
                }
                ref={exportDataLink}
              />
            </Grid>
          </Grid>
          <Box pt={2}>
            <DataGrid
              getRowId={(row) => row.uuid}
              rows={reportData}
              columns={columns}
              disableSelectionOnClick
              autoHeight
            />
          </Box>
        </React.Fragment>
      )}
    </>
  );
};

export default ReportIp;
