import React, { useState, useRef } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import GetAppIcon from "@material-ui/icons/GetApp";
import InfoIcon from "@material-ui/icons/Info";
import { CSVLink } from "react-csv";
import axios from "axios";
import ReportIp from "./ReportIp";
import Layout from "../layouts/Layout";
import { isSuperAdmin } from "../auth/roles";

const Report = (props) => {
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(undefined);
  const [fromDate, setFromDate] = useState(
    new Date(moment().subtract(30, "days"))
  );
  const [ipReportData, setIPReportData] = useState(undefined);
  const [toDate, setToDate] = useState(new Date(moment().add(1, "days")));
  const [message, setMessage] = useState(null);
  const exportDataLink = useRef();
  const [error, setError] = useState(undefined);

  const [ipReport, setIPReport] = useState(false);

  const generateReport = (is_billing_report) => {
    setLoading(true);
    axios
      .get("/devices/report", {
        params: {
          from_date: moment(fromDate).format("yyyy-MM-DD"),
          to_date: moment(toDate).format("yyyy-MM-DD"),
          is_billing_report,
        },
      })
      .then(({ data }) => {
        setReportData(data);
        setMessage(`Report Generated Successfully.`);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateNetworkingReport = () => {
    setLoading(true);
    axios
      .get(
        `/device/ip_report?start_date=${moment(fromDate).format(
          "yyyy-MM-DD"
        )}&end_date=${moment(toDate).format("yyyy-MM-DD")}`
      )
      .then(({ data }) => {
        setIPReportData(data);
        setMessage(`Report Generated Successfully.`);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const export_headers = [
    { label: "Device Id", key: "device.serial_no" },
    { label: "Action", key: "action" },
    { label: "Old Port Group", key: "old_value" },
    { label: "New Port Group", key: "new_value" },
    { label: "Action Taken At", key: "created_at" },
    { label: "Action Taken By", key: "taken_by.full_name" },
  ];

  const columns = [
    {
      field: "device.serial_no",
      headerName: "Device Serial No",
      minWidth: 300,
      valueGetter: (params) => params.row.device.serial_no,
      flex: 1,
    },
    { field: "action", headerName: "Action", minWidth: 300, flex: 1 },
    {
      field: "old_value",
      headerName: "Old Value",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) => params.row.old_value,
    },
    {
      field: "new_value",
      headerName: "New Value",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) => params.row.new_value,
    },
    {
      field: "created_at",
      headerName: "Action Taken At",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        moment.utc(params.row.created_at).local().format("LLL"),
    },
    {
      field: "taken_by",
      headerName: "User",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) => params.row.taken_by?.full_name,
    },
  ];

  return (
    <Layout
      loading={loading}
      error={error}
      successMessage={message}
      pageTitle={props.title}
      containerMaxWidth="xl"
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={4}
          alignItems="center"
        >
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline-from"
              label="From Date"
              value={fromDate}
              onChange={(date) => {
                setFromDate(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline-from"
              label="To Date"
              value={toDate}
              onChange={(date) => {
                setToDate(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIPReport(false);
                generateReport(false);
              }}
            >
              Generate Full Report
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setIPReport(false);
                generateReport(true);
              }}
            >
              Generate Billing Report
            </Button>
            <Tooltip
              title="Click to generate billing report. This will generate a report of actions taken 
              Change Port Group
              De-Activate
              Activate
              Change Plan
              Assign Reseller"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {isSuperAdmin() && (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setIPReport(true);
                  setReportData(null);
                  generateNetworkingReport();
                }}
              >
                Generate Networking Report
              </Button>
              <Tooltip title="Click to generate Report for Networking features">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
      {reportData && reportData.length === 0 && (
        <Box pt={10}>
          <Typography variant="h5" align="center">
            No Report Data.
          </Typography>
        </Box>
      )}
      {reportData && reportData.length !== 0 && (
        <React.Fragment>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={() => {
                  exportDataLink.current.link.click();
                }}
              >
                Export
              </Button>
              <CSVLink
                data={reportData}
                headers={export_headers}
                filename={
                  reportData &&
                  `sparqfi_${moment().format("YYYYMMDD_hmmss")}.csv`
                }
                ref={exportDataLink}
              />
            </Grid>
          </Grid>
          <Box pt={2}>
            <DataGrid
              rows={reportData}
              columns={columns}
              disableSelectionOnClick
              autoHeight
            />
          </Box>
        </React.Fragment>
      )}

      {ipReport && <ReportIp reportData={ipReportData} />}

      <Snackbar
        open={message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => {
          setMessage(undefined);
        }}
      >
        <Alert
          onClose={() => {
            setMessage(undefined);
          }}
          severity="success"
        >
          {message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

Report.defaultProps = {
  title: "Generate Report",
};

export default Report;
